import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import styles from './invite.module.css';

import photo1 from '../../assets/mosteiro.jpg';

import { FaRegCalendarAlt, FaRegClock, FaMapMarkerAlt } from 'react-icons/fa';
import { SlArrowDown } from "react-icons/sl";


class Invite extends Component {
  state = {};
  render() {
    const text = {};
    let language = this.props.lang;
    
    let body_pt = 'A nossa história começou há 8 anos, quando os nossos caminhos se cruzaram pela primeira vez no Aeroporto de Lisboa. A Inês, cheia de coragem, mudava-se do Porto para seguir os seus sonhos, enquanto o Hugo abraçava a sua primeira oportunidade profissional depois de terminar o mestrado. Entre voos, sorrisos e momentos partilhados, nasceu um amor doce, que agora culmina na promessa de uma vida inteira juntos. Assim sendo, gostariamos muito de partilhar com vocês este dia tão importante nas nossas vidas.';
    let body_en = 'Our story began 8 years ago, when our paths crossed for the first time at Lisbon Airport. Inês, full of courage, was moving from Porto to follow her dreams, while Hugo embraced his first professional opportunity after completing his master\'s degree. Between flights, smiles, and shared moments, a sweet love was born, which now culminates in the promise of a lifetime together. Therefore, we are thrilled to share this very important day in our lives with all of you.';

    text.introText = language === 'en' ? 'Dear Family and Friends' : 'Querida Família e Amigos,';
    text.bodyText = language === 'en' ? body_en : body_pt;
    text.confirmationText1 = language === 'en' ? 'If you\'d like to continue making history with us, please confirm.' : 'Caso queiram continuar a fazer história connosco, confirmem';
    text.linkText = language === 'en' ? 'here' : 'aqui';
    text.confirmationText2 = language === 'en' ? 'your presence' : 'a vossa presença.';

    return (
      <div>
        <div>
          <section className={styles.welcomeInviteContainer}>
            <div className={styles.welcomeInviteText}>
              <h4 style={{ fontFamily: "CormorantGaramondRegular" }} 
                  className={`${styles.headerText} ${styles.inviteWelcomeText} ${styles.mobileScreenInvite}`}>
                  {language === 'en' ? 'Invite' : 'Convite'}
              </h4>
              <SlArrowDown className={styles.downArrow} />
              <div className={styles.laptopScreenInvite}>
                <p className="subHeaderFontStyle">
                  <b> {text.introText} </b>
                </p>
                <p className="normalFontStyle">{text.bodyText}</p>
                <p className="normalFontStyle">
                  {text.confirmationText1}{' '}
                  {/* <a href={'/confirmation'}> */}
                  <a href={language ? `/confirmation/${language}` : '/confirmation'}>
                    <b>
                      <u>{text.linkText}</u>
                    </b>
                  </a>{' '}
                  {text.confirmationText2}
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className={`${styles.mobileScreenInvite} shadow-sm p-3 mb-5 bg-white rounded`}>
              <p className="subHeaderFontStyle">
                <b> {text.introText} </b>
              </p>
              <p className="normalFontStyle">{text.bodyText}</p>
              <p className="normalFontStyle">
                {text.confirmationText1}{' '}
                <a href={language ? `/confirmation/${language}` : '/confirmation'}>
                  <b>
                    <u>{text.linkText}</u>
                  </b>
                </a>{' '}
                {text.confirmationText2}
              </p>
            </div>
          </section>

          <div style={{ marginTop: '3rem' }}>
            <a 
            href="https://maps.app.goo.gl/igfziuX9qwWJw7rLA" target="_blank" rel="noreferrer" style={{ color: 'black' }}
            >
              <img src={photo1} className={styles.inviteLogo} alt="" />
            </a>

            <section className={styles.inviteInfoContainer}>
              <Container className={styles.inviteInfoContainerElement}>
                <div>
                  <FaRegCalendarAlt className={`${styles.inviteIconStyle} color-primary`} />
                  <h5 className="normalFontStyle2">
                  {language === 'en' ? '02 May 2025' : '02 de Maio de 2025'}
                    {/* 02 de Maio */}
                    </h5>
                </div>
              </Container>
              <Container>
                <div>
                  <FaRegClock className={`${styles.inviteIconStyle} color-primary`} />
                  <h5 className="normalFontStyle2">14:00</h5>
                </div>
              </Container>
              <Container>
                <a href="https://maps.app.goo.gl/igfziuX9qwWJw7rLA" target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                  <div>
                    <FaMapMarkerAlt className={`${styles.inviteIconStyle} color-primary`} />
                    <h5 className="normalFontStyle2">
                    {language === 'en' ? 'Moreira da Maia Monastery' :'Mosteiro Moreira da Maia'}
                      </h5>
                  </div>
                </a>
              </Container>
              <Container>
                <a href="https://maps.app.goo.gl/B7eAuGZPdYZS14cs9" target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                  <div>
                    <FaMapMarkerAlt className={`${styles.inviteIconStyle} color-primary`} />
                    <h5 className="normalFontStyle2">Quinta do Alferes de Crasto, Vila do Conde</h5>
                  </div>
                </a>
              </Container>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Invite;
