import React, { useState, useEffect, useRef } from 'react';
import Burger from '../burger/burger';
import BurgerMenu from '../burgerMenu/burgerMenu';

import { useOnClickOutside } from '../../hooks';

import monogramWhite from '../../assets/logo-escrito.png';

import styles from './nav-bar.module.css';

const NavBar = (props) => {
  const language = props.lang;
  // console.log(`Navbar isComputer? ${props.isComputer}`);
  const [open, setOpen] = useState(false);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY >100);
    });
  }, []);

  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  return (
    <div>
      <div className={`${scroll ? styles.outerContainerScrolled : styles.outerContainer}`}>
        <a href={language ? `/home/${language}` : '/home'}>
          <img className={styles.monogram} src={monogramWhite} alt="monogram" />
        </a>
      </div>
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} scrolled={scroll} />
        <BurgerMenu open={open} lang={props.lang} setOpen={setOpen} />
      </div>
    </div>
  );
};

export default NavBar;
