import React from 'react';

import './styles.css';

import { SlArrowDown } from 'react-icons/sl';

class Gifts extends React.Component {
  render() {
    const Esmola = {};
    let language = this.props.lang;

    Esmola.introText = language === 'en' ? 'Dear Family and Friends' : 'Querida Família e Amigos,';
    Esmola.bodyText = 
      language === 'en' ?
        'The best gift you can give us is to be present on this special day!' :
        'O melhor presente que nos podem oferecer é estarem presentes neste dia tão especial!';
    
    Esmola.bodyText2 =
      language === 'en' ?
        'However, if you want to contribute to our honeymoon or help us furnish our home, please contact us directly.' :
        'No entanto, caso queiram contribuir para a nossa lua-de-mel ou para nos ajudar a mobilar a nossa casa, entrem diretamente em contacto connosco.';
    
    Esmola.titular1 = language === 'en' ? 'Maria Inês Fernandes Castro' : 'Maria Inês Fernandes Castro';
    Esmola.Iban1 = language === 'en' ? 'Phone nr: +351 937 935 684' : 'Nr. telemóvel: +351 937 935 684';
    Esmola.titular2 = language === 'en' ? 'Hugo Alexandre Neves de Sousa' : 'Hugo Alexandre Neves de Sousa';
    Esmola.Iban2 = language === 'en' ? 'Phone nr: +351 915 222 990' : 'Nr. telemóvel: +351 915 222 990';

    Esmola.agradecer = 
      language === 'en' ?
        'Please identify the transfers so we can thank you.' :
        'Por favor, identifiquem as transferências para que possamos agradecer.';
        
    Esmola.ty = language === 'en' ? 'Thank you!' : 'Muito obrigado!';

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let isAndroidPhone = /android/i.test(userAgent);

    return (
      <div>
        <section className={`${isAndroidPhone ? "gifts-welcome-container-android" : ""} gifts-welcome-container`}>
          <div className="gifts-welcome-text">
            <h4
              className="headerText"
              style={{
                fontFamily: 'CormorantGaramondRegular',
              }}
            >{language === 'en' ? 'Wedding Gifts' : 'Lista de Casamento'}
            </h4>
            <SlArrowDown className="downArrow" />
          </div>
        </section>
        <section>
          <div className="giftsTextbox shadow-sm p-3 mb-5 bg-white rounded">
            <p className="subHeaderFontStyle"><b>{Esmola.introText}</b></p>
            <p className="normalFontStyle">{Esmola.bodyText}</p>
            <p className="normalFontStyle">{Esmola.bodyText2}</p>

            <p className="normalFontStyle">
              <b> {Esmola.titular1}</b>
              <br></br>
              <b>{Esmola.Iban1}</b>
            </p>

            <p className="normalFontStyle">
              <b> {Esmola.titular2}</b>
              <br></br>
              <b>{Esmola.Iban2}</b>
            </p>

            {/* <p className="normalFontStyle">
              <i>{Esmola.agradecer}</i>
            </p> */}

            <p className="normalFontStyle">{Esmola.ty}</p>
          </div>
        </section>
      </div>
    );
  }
}

export default Gifts;
