import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import styles from './to_stay.module.css';

import { SlArrowDown } from "react-icons/sl";

class Stay extends Component {
  state = {};
  render() {
    const text = {};
    let language = this.props.lang;
    
    text.introText = language === 'en' ? 'Dear Family and Friends' : 'Querida Família e Amigos,';
    text.bodyText = 
      language === 'en' ?
        'To make your stay easier to plan, here are some suggestions for hotels near Quinta Alferes de Crasto where you can sleep over. These are just suggestions so feel free to choose other places!' :
        'De modo a facilitar o planeamento da vossa estadia, deixamos aqui algumas sugestões de hóteis, perto da quinta, onde poderão ficar. Estas são apenas sugestões pelo que estejam à vontade para escolher outros locais!';
    
    text.noteText = 
      language === 'en' ?
        'We do not have any control over the reservations, nor any partnership with the suggested hotels, so if you find a hotel from this list fully booked, please contact us so we can remove them from this page.' :
        'Nós não temos qualquer controlo sob as reservas, nem qualquer parceria com os hóteis sugeridos, pelo que se encontrarem algum destes hóteis cheios, por favor avisem-nos para que o retiremos desta lista.';

    return (
      <div>
        <div>
          <section className={styles.Stay_Welcome_Container}>
            <div className={styles.Stay_Welcome_Text}>
              <h4
                className={styles.headerText}
                style={{
                  fontFamily: 'CormorantGaramondRegular',
                }}
              >{language === 'en' ? 'Where to Stay?' : 'Onde Ficar?'}
              </h4>
            <SlArrowDown className={styles.downArrow} />
            </div>
          </section>

        <section>
          <div className= {`${styles.stayTextbox} shadow-sm p-3 mb-3 bg-white rounded`}>
            <p className="subHeaderFontStyle">
              <b> {text.introText} </b>
            </p>
            <p className="normalFontStyle">{text.bodyText}</p>
            <p className="normalFontStyle"><i>{text.noteText}</i></p>
          </div>
        </section>

{/* ---------------------------------------- */}
        <section>
          <div className={styles.distance}>
            <p>
              {language === 'en' ? 'Close to the wedding venue' : 'Perto da quinta'}
            </p>
          </div>
        </section>

        <section className={styles.HotelContainer}>
          <Container className={styles.HotelItem}>
            <div>
              <a 
                href="https://www.booking.com/hotel/pt/hotelsantana.html" target="_blank" rel="noreferrer" style={{ color: 'black' }}
                >
                  <img 
                    src="https://cf.bstatic.com/xdata/images/hotel/max1280x900/33604268.jpg?k=864fbb266ceae9bc4cd5689fd5419dcd1e32912402dbda3e0bda11e122c632ba&o=&hp=1"
                    alt="" 
                    className={styles.HotelImage}
                  />
              </a>
              <h5 className="normalFontStyle">
              {language === 'en' ? 'Santana Hotel 4*' : 'Santana Hotel 4*'}
                </h5>
            </div>
          </Container>

          <Container className={styles.HotelItem}>
            <div>
              <a 
                href="https://www.booking.com/hotel/pt/villa-c-spa.html" target="_blank" rel="noreferrer" style={{ color: 'black' }}
                >
                  <img 
                    src="https://cf.bstatic.com/xdata/images/hotel/max1280x900/118548645.jpg?k=a1ca44110cd3f80e7252c534373a42d48c35264d672325db7dfb4417bb187131&o=&hp=1"
                    alt="" 
                    className={styles.HotelImage}
                  />
              </a>
              <h5 className="normalFontStyle">
              {language === 'en' ? 'Villa C Boutique Hotel 4*' : 'Villa C Boutique Hotel 4*'}
                </h5>
            </div>
          </Container>

          <Container className={styles.HotelItem}>
            <div>
              <a 
                href="https://www.booking.com/hotel/pt/a-rendilheira-boutique-vila-do-conde.html" target="_blank" rel="noreferrer" style={{ color: 'black' }}
                >
                  <img 
                    src="https://cf.bstatic.com/xdata/images/hotel/max1280x900/226067256.jpg?k=7d5bb57bb817be61029db1940927dcdb1828eb929a7378faed4fc5c0a5933146&o=&hp=1"
                    alt="" 
                    className={styles.HotelImage}
                  />
              </a>
              <h5 className="normalFontStyle">
              {language === 'en' ? 'A Rendilheira Boutique Hotel' : 'A Rendilheira Boutique Hotel'}
                </h5>
            </div>
          </Container>
                      
        </section>
        
        </div>
      </div>
    );
  }
}

export default Stay;
