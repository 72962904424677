import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';

import emailjs from 'emailjs-com';
import './styles.css';

import { SlArrowDown } from 'react-icons/sl';

const maxGuests = 4;

const userId = 'u_MH2AbwEfAOx01rm';
const emailServiceId = 'service_aemn5qu';
const templateIdGoing = 'template_3s7yk16';
const templateIdNotGoing = 'template_1dpxnpf';

const waitingStatus = 'waiting';
const sendingStatus = 'sending';
const sentStatus = 'sent';
const failedStatus = 'failed';

class Confirmation extends Component {
  state = {
    language: '',
    chose: false,
    isGoing: false,
    address: '',
    postalCode: '',
    country: '',
    city: '',
    email: '',
    firstName: '',
    lastName: '',
    foodText: '',
    songText: '',
    cocktailText: '',
    invites: [],
    sendStatus: waitingStatus,
  };

  language = this.props.lang;

  handleAddNames = () => {
    console.log(`HandleAddNames lang: ${this.props.lang}`);
    const index = this.state.invites.length;
    const key = `invite${index}`;
    const firstName = `firstName${index}`;
    const lastName = `lastName${index}`;

    const newElement = (
      <div key={key} className="form-row">
        <div className="col">
          <Form.Group controlId={firstName}>
            <Form.Label>{this.props.lang === 'en' ? 'Name' : 'Nome'}</Form.Label>
            <Form.Control
              type="text"
              name={firstName}
              placeholder={this.props.lang === 'en' ? 'Add name' : 'Adicionar nome'}
            />
          </Form.Group>
        </div>

        <div className="col">
          <Form.Group controlId={lastName}>
            <Form.Label>{this.props.lang === 'en' ? 'Surname' : 'Apelido'}</Form.Label>
            <Form.Control
              type="text"
              name={lastName}
              placeholder={this.props.lang === 'en' ? 'Add surname' : 'Adicionar apelido'}
            />
          </Form.Group>
        </div>
      </div>
    );
    this.setState((prevState) => ({
      invites: [...(prevState.invites || []), newElement],
    }));

  };

  handleRemoveNames = () => {
    let invites = this.state.invites;
    let newInvites = [];

    for (let i = 0; i < invites.length - 1; i++) {
      newInvites.push(invites[i]);
    }

    this.setState({ invites: newInvites }, () => {
    });
  };

  handleFoodTextChange = (event) => {
    this.setState({ foodText: event.target.value }, () => {
    });
  };

  handleSongTextChange = (event) => {
    this.setState({ songText: event.target.value }, () => {
    });
  };

  handleCocktailTextChange = (event) => {
    this.setState({ cocktailText: event.target.value }, () => {
    });
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value }, () => {
    });
  };

  handleFirstNameChange = (event) => {
    this.setState({ firstName: event.target.value }, () => {
    });
  };

  handleLastNameChange = (event) => {
    this.setState({ lastName: event.target.value }, () => {
    });
  };

  handleAddressChange = (event) => {
    this.setState({ address: event.target.value }, () => {
    });
  };

  handleCityChange = (event) => {
    this.setState({ city: event.target.value }, () => {
    });
  };

  handlePostalCodeChange = (event) => {
    this.setState({ postalCode: event.target.value }, () => {
    });
  };

  handleCountryChange = (event) => {
    this.setState({ country: event.target.value }, () => {
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ sendStatus: sendingStatus }, () => {
      let guests = [];
      for (let i = 0; i < maxGuests; i++) {
        const element = this.state[`inviteNames${i}`];

        if (element) {
          guests.push(element);
        }
      }

      guests.forEach((el, i) => {
        if (i === 0) console.log('How many? - ' + guests.length);
        console.log(`Name of extra guest ${i} -> ` + guests[i]);
      });

      emailjs.sendForm(emailServiceId, this.state.isGoing === true ? templateIdGoing : templateIdNotGoing, event.target, userId).then(
        (result) => {
          console.log(result);
          this.setState({ sendStatus: sentStatus }, () => { });
        },
        (error) => {
          console.log(error.text);
          window.alert('Ocorreu um erro. Por favor contacte os noivos.');
          this.setState({ sendStatus: failedStatus }, () => { });
        }
      );

    });
  };

  handleInviteNamesChange = (event) => {
    this.setState({ [event.target.id]: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  isSubmitDisabled = () => {
    const state = this.state;
    if (state.isGoing === true) {
      return !(
        state.lastName.length > 0 &&
        state.email.length > 0 &&
        state.firstName.length > 0
      );
    } else {
      return !(state.lastName.length > 0 && state.email.length > 0 && state.firstName.length > 0 && state.chose === true);
    }
  };

  handleIsGoingChange = (event) => {
    this.setState({ chose: true }, () => {
      if (event.target.value === 'true') {
        this.setState({ isGoing: true }, () => { });
      } else {
        this.setState({ isGoing: false }, () => {
          this.setState({ songText: '' }, () => {
            this.setState({ foodText: '' }, () => { });
          });
        });
      }
    });
  };

  renderSubmitButton = () => {
    switch (this.state.sendStatus) {
      case waitingStatus:
        return this.language === 'en' ? 'Submit' : 'Submeter';;
      case sendingStatus:
        return (
          <div className="loadingDots">
            <div className="loadingDot"></div>
            <div className="loadingDot"></div>
            <div className="loadingDot"></div>
          </div>
        );
      case sentStatus:
        return this.language === 'en' ? 'Response sent. Thank you!' : 'Resposta enviada. Obrigado!';

      case failedStatus:
        return this.language === 'en' ? 'Error!' : 'Erro!';

      default:
        break;
    }
  };

  render() {
    return (
      <div>
        <section className="confirmation-welcome-container">
          <div className="confirmation-welcome-text">
            <h4
              className="headerText"
              style={{
                fontFamily: 'CormorantGaramondRegular',
              }}
            >
              {this.language === 'en' ? 'Confirm your attendance!' : 'Confirma a tua presença!'}
            </h4>
            <SlArrowDown className="downArrow" />
          </div>
        </section>

        <section className="formSection">
          <Form className="formStyle" onSubmit={this.handleSubmit}>
            <div className="form-row">
              <div className="col">
                <Form.Group controlId="firstName">
                  <Form.Label>{this.language === 'en' ? 'Name *' : 'Nome *'}</Form.Label>
                  <Form.Control defaultValue={this.state.firstName} onChange={this.handleFirstNameChange} type="text" name="firstName" placeholder={this.language === 'en' ? 'Add name' : 'Adicionar nome'} />
                </Form.Group>
              </div>

              <div className="col">
                <Form.Group controlId="lastName">
                  <Form.Label>{this.language === 'en' ? 'Surname *' : 'Apelido *'}</Form.Label>
                  <Form.Control defaultValue={this.state.lastName} onChange={this.handleLastNameChange} type="text" name="lastName" placeholder={this.language === 'en' ? 'Add surname' : 'Adicionar apelido'} />
                </Form.Group>
              </div>
            </div>
            
            {this.state.invites}
            <div className="manageInviteButtons">
              <Button disabled={this.state.invites.length === maxGuests} className="manageInviteButtonStyle" variant="outline-primary" size="sm" onClick={this.handleAddNames}>
                +
              </Button>
              <Button disabled={this.state.invites.length === 0} className="manageInviteButtonStyle" variant="outline-primary" size="sm" onClick={this.handleRemoveNames}>
                -
              </Button>
            </div>

            <div className="form-row">
              <div className="col">
                <Form.Group controlId="email">
                  <Form.Label>{this.language === 'en' ? 'E-mail address *' : 'Endereço e-mail *'}</Form.Label>
                  <Form.Control defaultValue={this.state.email} onChange={this.handleEmailChange} type="email" name="email" placeholder={this.language === 'en' ? 'example@domain.com' : 'exemplo@dominio.com'} />
                </Form.Group>
              </div>
              
            </div>
            <div className="form-row">
              <div className="col">
                <Form.Group controlId="isGoing">
                  <label>{this.language === 'en' ? 'Are you joining us? *' : 'Contamos contigo? *'}</label>
                  <select onChange={this.handleIsGoingChange} id="isGoing" name="isGoing" className="form-control" defaultValue="none">
                    <option disabled value="none">
                      {this.language === 'en' ? 'Choose' : 'Escolha'}
                    </option>
                    <option value="true">{this.language === 'en' ? 'Yes' : 'Sim'}</option>
                    <option value="false">{this.language === 'en' ? 'No' : 'Não'}</option>
                  </select>
                </Form.Group>
              </div>
              <div className="col"></div>
            </div>
            
            {this.state.isGoing === true ? (
              <div>

                <div className="form-row">
                  <div className="col">
                    <Form.Group controlId="foodText">
                    <Form.Label>{this.language === 'en' ? 'Food restrictions' : 'Restrições Alimentares'}</Form.Label>
                      <textarea 
                        value={this.state.foodText}
                        className="textAreaStyle"
                        placeholder={
                          this.language === 'en' 
                          ? 'If you have any food allergies or restrictions, please mention them here. (Optional)' 
                          : 'Caso tenha alergias ou intolerâncias, especifique-as aqui por favor. (Opcional)'
                          } 
                          onChange={this.handleFoodTextChange} 
                          name="foodText"
                        />
                    </Form.Group>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col">
                    <Form.Group controlId="songText">
                    <Form.Label>{this.language === 'en' ? 'Music' : 'Música'}</Form.Label>
                      <textarea
                        value={this.state.songText}
                        className="textAreaStyle"
                        placeholder={
                          this.language === "en"
                            ? "What is the song that always gets you going? (Optional; One song per person)"
                            : "Qual é a música que te faz levantar da cadeira? (Opcional; Uma música por pessoa)"
                        }
                        onChange={this.handleSongTextChange}
                        name="songText"
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col">
                    <Form.Group controlId="cocktailText">
                    <Form.Label>{this.language === 'en' ? 'Cocktail/Drink' : 'Cocktail/Bebida'}</Form.Label>
                      <textarea
                        value={this.state.CocktailText}
                        className="textAreaStyle"
                        placeholder={
                          this.language === "en"
                            ? "What is your favority cocktail/drink? (Optional; One song per person)"
                            : "Qual é o teu cocktail/bebida favorita? (Opcional; Uma bebida por pessoa)"
                        }
                        onChange={this.handleCocktailTextChange}
                        name="cocktailText"
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            { <p style={{ color: "grey" }}>
              <i>
                {this.language === "en"
                  ? "* Required field"
                  : "* Campo obrigatório"}
              </i>
            </p> }
            <Button 
              disabled={this.state.sendStatus !== waitingStatus || this.isSubmitDisabled()} 
              variant="primary" 
              type="submit" 
              className='submit-button'
            >
              {this.renderSubmitButton()}
            </Button>
          </Form>
        </section>
      </div>
    );
  }
}

export default Confirmation;
