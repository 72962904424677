import React from "react";
import styles from "./home.module.css";

import CountdownTimer from "../countdownTimer/countdownTimer";

const dateTo = new Date("2025-05-02T14:00:00");

const Home = (props) => {
  return (
    <div>
      <section className={styles.welcomeHomeContainer}>
        <div className={styles.welcomeHomeText}>
          <h4 className={styles.saveTheDateText}>
            <b>SAVE </b>
            <i
              style={{
                fontFamily: "HoneyScriptLight",
              }}
            >
              the
            </i>
            <b> DATE</b>
          </h4>
          <h5 className={styles.dateText}>
            {/* 02 . MAIO . 2025 */}
            {props.lang === "en" ? "02 . MAY . 2025" : "02 . MAIO . 2025"}
          </h5>
          <div style={{ bottom: "3rem", position: "fixed" }}>
            <CountdownTimer
              props={props}
              dateTo={dateTo}
              headerClasses="centered"
              classes="centered"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
