// import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";
import { useMediaQuery } from "react-responsive";
import NavBar from "./components/nav-bar/nav-bar";
import Home from "./components/home/home";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Confirmation from "./components/confirmation/confirmation";
import Invite from "./components/invite/invite";
import Gifts from "./components/gifts/gifts";
import ToStay from "./components/to_stay/to_stay";

// import { colors } from "./colors";

function App() {
  const isComputer = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const mainStyles = {
    // backgroundColor: "#DCDCDC",
    backgroundColor: "white",
    // backgroundColor: "#F5F5F5",
  };

  const NavRoute = ({ lang, exact, path, component: Component }) => (
    <Route
      exact={exact}
      path={path}
      render={(props) => (
        <div>
          <NavBar isComputer={isComputer} lang={lang} />
          <Component lang={lang} isComputer={isComputer} {...props} />
        </div>
      )}
    />
  );
  return (
    <div style={mainStyles}>
      <Router>
        <Switch>
          <NavRoute exact path="/home" component={Home} />
          <NavRoute lang={"en"} exact path="/home/en" component={Home} />

          <NavRoute exact path="/invite" component={Invite} />
          <NavRoute lang={"en"} exact path="/invite/en" component={Invite} />

          <NavRoute exact path="/confirmation" component={Confirmation} />
          <NavRoute lang={"en"} exact path="/confirmation/en" component={Confirmation} />

          {/* <NavRoute exact path="/location" component={Location} /> */}
          
          <NavRoute exact path="/toStay" component={ToStay} />
          <NavRoute lang={"en"} exact path="/toStay/en" component={ToStay} />

          <NavRoute exact path="/gifts" component={Gifts} />
          <NavRoute lang={"en"} exact path="/gifts/en" component={Gifts} />
          
          <NavRoute exact path="/" component={Home} />
          <NavRoute exact path="*" component={Home} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
